import { MuiFileInput } from "mui-file-input";

import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import internshipRegistrationServices from "../../Services/InternshipRegistrationServices";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomTextField from "../../GenericComponents/CustomTextField";
import domainServices from "../../Services/DomainServices";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomFileInput from "../../GenericComponents/CustomFileInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDatePicker from "../../GenericComponents/CustomDatePicker";
import internshipCertificateServices from "../../Services/InternshipCertificateServices";
import dayjs from "dayjs";
import Form_Instructions from "../../Content/Instructions/Form_Instructions";

const formLabelsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});

const sectionGridStyle = {
  borderRadius: 2,
  p: 2,
  boxShadow: 1,
  backgroundColor: "#F5F5F5",
};

const sectionHeadingStyle = {
  fontFamily: "inherit",
  fontWeight: "bold",
  textTransform: "uppercase",
  mx: 2,
};

const sectionContentStyle = {
  display: "grid",
  gap: 2,
  mx: 2,
  mt: 2,
  mb: 1,
  p: 3,
  backgroundColor: "white",
  borderRadius: 2,
};

function Intern() {
  const formElement = {
    width: "90%",
    margin: "10px",
  };
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const path = window.location.pathname;
  const isRegister = path.includes("register") ? true : false;

  const [open, setOpen] = useState(true);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState(isRegister ? null : state.email);
  const [phoneNo, setPhoneNo] = React.useState("");
  const [gender, setGender] = React.useState(null);
  const [dob, setDOB] = React.useState(null);
  const [city, setCity] = React.useState("");

  const [degree, setDegree] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const [yop, setYOP] = React.useState(null);
  const [cgpaPercentage, setCgpaPercentage] = React.useState("");
  const [collegeName, setCollegeName] = React.useState("");
  const [diploma12Yop, setDiploma12Yop] = React.useState(null);
  const [diploma12Percentage, setDiploma12Percentage] = React.useState("");
  const [diploma12College, setDiploma12College] = React.useState("");
  const [yop10, setYop10] = React.useState(null);
  const [percentage10, setPercentage10] = React.useState("");
  const [schoolName10, setSchoolName10] = React.useState("");
  const [activeBacklog, setActiveBacklog] = React.useState("");

  const [resumeFile, setResumeFile] = React.useState(null);
  const [photoFile, setPhotoFile] = React.useState(null);
  const [idFile, setIdFile] = React.useState(null);
  const [paymentReceiptFile, setPaymentReceiptFile] = useState(null);

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const [selectedDomain, setSelectedDomain] = useState(
    isRegister ? null : state.domain
  );
  const [duration, setDuration] = useState(isRegister ? null : state.duration);

  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneNoError, setPhoneNoError] = React.useState("");
  const [genderError, setGenderError] = React.useState("");
  const [dobError, setDOBError] = React.useState("");
  const [cityError, setCityError] = React.useState("");
  const [degreeError, setDegreeError] = React.useState("");
  const [branchError, setBranchError] = React.useState("");
  const [yopError, setYOPError] = React.useState("");
  const [cgpaPercentageError, setCgpaPercentageError] = React.useState("");
  const [collegeNameError, setCollegeNameError] = React.useState("");
  const [diploma12YopError, setDiploma12YopError] = React.useState("");
  const [diploma12PercentageError, setDiploma12PercentageError] =
    React.useState("");
  const [diploma12CollegeError, setDiploma12CollegeError] = React.useState("");
  const [yop10Error, setYop10Error] = React.useState("");
  const [percentage10Error, setPercentage10Error] = React.useState("");
  const [schoolName10Error, setSchoolName10Error] = React.useState("");
  const [activeBacklogError, setActiveBacklogError] = React.useState("");
  const [resumeFileError, setResumeFileError] = React.useState(null);
  const [photoFileError, setPhotoFileError] = React.useState(null);
  const [idFileError, setIdFileError] = React.useState(null);
  const [paymentReceiptFileError, setPaymentReceiptFileError] = useState(null);
  const [selectedDomainError, setSelectedDomainError] = useState("");
  const [durationError, setDurationError] = useState("");

  const [domains, setDomains] = useState([]);

  // Autocomplete props

  const genderList = ["Male", "Female", "Others"];
  const genderProps = {
    options: genderList,
  };

  const domainProps = {
    options: domains,
    getOptionLabel: (option) => option.name,
  };

  const durationOptions = ["1 month", "2 months", "3 months", "6 months"];
  const durationProps = {
    options: durationOptions,
  };

  const handleResumeFileChange = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      setResumeFile(acceptedFiles[0]);
    } else {
      console.log("ERROR");
    }
  };

  const handleChangeResume = (newFile) => {
    setResumeFileError("");
    setResumeFile(newFile);
  };

  const handleChangePhoto = (newFile) => {
    setPhotoFile("");
    setPhotoFile(newFile);
  };

  const handleChangeID = (newFile) => {
    setIdFileError("");
    setIdFile(newFile);
  };

  const handleChangePaymentReceipt = (newFile) => {
    setPaymentReceiptFileError("");
    setPaymentReceiptFile(newFile);
  };

  // Prevent right-click context menu

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  //Restrict All textField
  const handlenamechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setNameError("");
    setName(newValue);
  };
  const handleEmailchange = (event) => {
    if (isRegister) {
      const { value } = event.target; // Only allow alphanumeric and Special  characters
      const newValue = value.replace(/[^A-Za-z0-9@._]/g, "");
      setEmailError("");
      setEmail(newValue);
    }
  };
  // const handlePhoneNochange = (event) => {
  //   const { value } = event.target; // Only allow numeric characters
  //   const newValue = value.replace(/[^0-9]/g, "");

  //   setPhoneNo(newValue);
  // };
  const handlePhoneNochange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 10 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    setPhoneNoError("");
    setPhoneNo(newValue);
  };
  const handleGenderChange = (event, value) => {
    setGenderError("");
    setGender(value);
  };
  const handleDOBChange = (value) => {
    setDOBError("");
    setDOB(value);
  };
  const handleCitychange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setCityError("");
    setCity(newValue);
  };
  const handleDegreechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setDegreeError("");
    setDegree(newValue);
  };
  const handleBranchchange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setBranchError("");
    setBranch(newValue);
  };
  const handleYOPchange = (value) => {
    setYOPError("");
    setYOP(value);
  };

  const handlecgpaPercentagechange = (event) => {
    const { value } = event.target; // Only allow alphanumeric characters
    //const newValue = value.replace(/[^A-Za-z0-9%]/g, "");
    const newValue = value.replace(/[^0-9%.]/g, "").slice(0, 3);
    setCgpaPercentageError("");
    setCgpaPercentage(newValue);
  };
  const handleCollegeNamechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setCollegeNameError("");
    setCollegeName(newValue);
  };
  const handleDiploma12Yopchange = (value) => {
    setDiploma12YopError("");
    setDiploma12Yop(value);
  };
  const handleDiploma12Percentagechange = (event) => {
    const { value } = event.target; // Only allow alphaNumeric and special  characters
    //const newValue = value.replace(/[^A-Za-z0-9%]/g, "");
    const newValue = value.replace(/[^0-9%.]/g, "").slice(0, 3);
    setDiploma12PercentageError("");
    setDiploma12Percentage(newValue);
  };
  const handlediploma12Collegechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setDiploma12CollegeError("");
    setDiploma12College(newValue);
  };
  const handleYop10change = (value) => {
    setYop10Error("");
    setYop10(value);
  };
  const handlePercentage10change = (event) => {
    const { value } = event.target; // Only allow alphanumeric and special characters
    // const newValue = value.replace(/[^A-Za-z0-9%]/g, "");
    const newValue = value.replace(/[^0-9%.]/g, "").slice(0, 3);
    setPercentage10Error("");
    setPercentage10(newValue);
  };
  const handleSchoolName10change = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setSchoolName10Error("");
    setSchoolName10(newValue);
  };
  const handleactiveBacklogchange = (event) => {
    const { value } = event.target; // Only allow numeric characters
    //const newValue = value.replace(/[^0-2]/g, "");
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 2);
    setActiveBacklogError("");
    setActiveBacklog(newValue);
  };
  const handleSelectedDomainChange = (event, value) => {
    setSelectedDomainError("");
    setSelectedDomain(value);
  };
  const handleDurationChange = (event, value) => {
    setDurationError("");
    setDuration(value);
  };

  const validateFields = (e) => {
    setNameError(name ? "" : "This field is required");
    setEmailError(email ? "" : "This field is required");
    setPhoneNoError(phoneNo ? "" : "This field is required");
    setGenderError(gender ? "" : "This field is required");
    setDOBError(dob != null ? "" : "This field is required");
    setCityError(city ? "" : "This field is required");
    setDegreeError(degree ? "" : "This field is required");
    setBranchError(branch ? "" : "This field is required");
    setYOPError(yop != null ? "" : "This field is required");
    setCgpaPercentageError(cgpaPercentage ? "" : "This field is required");
    setCollegeNameError(collegeName ? "" : "This field is required");
    setDiploma12YopError(diploma12Yop != null ? "" : "This field is required");
    setDiploma12PercentageError(
      diploma12Percentage ? "" : "This field is required"
    );
    setDiploma12CollegeError(diploma12College ? "" : "This field is required");
    setYop10Error(yop10 != null ? "" : "This field is required");
    setPercentage10Error(percentage10 ? "" : "This field is required");
    setSchoolName10Error(schoolName10 ? "" : "This field is required");
    setActiveBacklogError(activeBacklog ? "" : "This field is required");
    setSelectedDomainError(selectedDomain ? "" : "This field is required");
    setDurationError(duration ? "" : "This field is required");
    setResumeFileError(resumeFile ? "" : "This field is required");
    setPhotoFileError(photoFile ? "" : "This field is required");
    setIdFileError(idFile ? "" : "This field is required");
    setPaymentReceiptFileError(
      paymentReceiptFile ? "" : "This field is required"
    );

    const isFormValid =
      (name &&
        email &&
        phoneNo &&
        dob != null &&
        city &&
        degree &&
        branch &&
        yop != null &&
        cgpaPercentage &&
        collegeName &&
        diploma12Yop != null &&
        diploma12Percentage &&
        diploma12College &&
        yop10 != null &&
        percentage10 &&
        schoolName10 &&
        activeBacklog) ||
      (isRegister
        ? resumeFile && photoFile && idFile && paymentReceiptFile
        : false);

    return isFormValid;
  };

  // const resetValues = (e) => {
  //   setName("");
  //   setEmail("");
  //   setPhoneNo("");
  //   setGender("");
  //   setDOB("");
  //   setCity("");
  //   setDegree("");
  //   setBranch("");
  //   setYOP("");
  //   setCgpaPercentage("");
  //   setCollegeName("");
  //   setDiploma12Yop("");
  //   setDiploma12Percentage("");
  //   setDiploma12College("");
  //   setYop10("");
  //   setPercentage10("");
  //   setSchoolName10("");
  //   setActiveBacklog("");
  //   setSelectedDomain("");
  //   setDuration("");
  //   setResumeFile(null);
  //   setPhotoFile(null);
  //   setIdFile(null);
  //   setPaymentReceiptFile(null);
  // };

  let { internType, domainId, durationIndex } = useParams();

  useEffect(() => {
    const fetchDomains = async () => {
      let data = await domainServices.getDomains();

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          // console.log(data.responseBody);
          setDomains(() => data.responseBody);
        }
      }
    };

    fetchDomains();
  }, []);

  // useEffect(() => {
  //   function getNextMondayDate(currentDate) {
  //     const dayOfWeek = currentDate.getDay();
  //     const daysUntilNextMonday =
  //       dayOfWeek === 1 ? 0 : dayOfWeek === 0 ? 1 : 8 - dayOfWeek;
  //     const nextMondayDate = new Date(currentDate);
  //     nextMondayDate.setDate(currentDate.getDate() + daysUntilNextMonday);
  //     return nextMondayDate;
  //   }

  //   function getNextFirstAndThirdMonday(currentDate) {
  //     const currentMonth = currentDate.getMonth();
  //     const currentYear = currentDate.getFullYear();

  //     // Get the date of the 1st Monday of the current month
  //     let firstMonday = new Date(currentYear, currentMonth, 1);
  //     firstMonday = getNextMondayDate(firstMonday);

  //     // Get the date of the 3rd Monday of the current month
  //     let thirdMonday = new Date(currentYear, currentMonth, 1);
  //     thirdMonday.setDate(thirdMonday.getDate() + 14); // Add 14 days for 2 weeks
  //     thirdMonday = getNextMondayDate(thirdMonday);

  //     // Calculate the dates for comparison
  //     const today = new Date();
  //     const nextFirstMonday =
  //       firstMonday > today
  //         ? firstMonday
  //         : getNextMondayDate(new Date(currentYear, currentMonth + 1, 1));
  //     const nextThirdMonday =
  //       thirdMonday > today
  //         ? thirdMonday
  //         : getNextMondayDate(new Date(currentYear, currentMonth + 1, 1));
  //     const oneWeekFromNow = new Date();
  //     oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

  //     // Determine which Monday to return based on the conditions
  //     if (today < oneWeekFromNow) {
  //       return nextThirdMonday;
  //     } else if (today < nextThirdMonday) {
  //       return nextFirstMonday;
  //     } else if (
  //       today <
  //       new Date(
  //         nextFirstMonday.getFullYear(),
  //         nextFirstMonday.getMonth(),
  //         nextFirstMonday.getDate() + 7
  //       )
  //     ) {
  //       return nextThirdMonday;
  //     } else {
  //       return nextFirstMonday;
  //     }
  //   }

  //   if (isRegister) {
  //     const currentDate = new Date(); // 4th May 2024
  //     const nextMonday = getNextFirstAndThirdMonday(currentDate);
  //     setStartDate(dayjs(nextMonday));
  //   }
  // }, []);

  useEffect(() => {
    if (domainId && domains.length > 0) {
      const domain = domains.find((item) => item.id === parseInt(domainId));
      setSelectedDomain(domain);
    }
    if (durationIndex) {
      setDuration(durationOptions[durationIndex]);
    }
  }, [domainId, domains, durationIndex]);

  const handleSubmit = async (event) => {
    if (validateFields()) {
      const files = [resumeFile, photoFile, idFile, paymentReceiptFile];
      console.log(files);
      const currentDate = new Date();
      const timestamp = currentDate.toISOString();
      const formattedDOB = dob.format("DD-MM-YYYY");
      const formattedYOP = yop.format("YYYY");
      const formattedDiploma12Yop = diploma12Yop.format("YYYY");
      const formattedYop10 = yop10.format("YYYY");
      const typeOfInternship =
        internType === "p"
          ? "Paid"
          : internType === "u"
          ? "Unpaid"
          : "Stipend Based";
      const jsonData = {
        domainId: selectedDomain.id,
        name: name,
        email: email,
        phoneNo: phoneNo,
        gender: gender,
        dob: formattedDOB,
        city: city,
        degree: degree,
        branch: branch,
        yop: formattedYOP,
        cgpa: cgpaPercentage,
        college_Name: collegeName,
        yop_12: formattedDiploma12Yop,
        percentage_12: diploma12Percentage,
        college_12: diploma12College,
        yop_10: formattedYop10,
        percentage_10: percentage10,
        school_10: schoolName10,
        active_Backlog: activeBacklog,
        duration: duration,
        startDate: isRegister ? "" : state.startDate,
        endDate: isRegister ? "" : state.endDate,
        typeOfInternship: isRegister
          ? typeOfInternship
          : state.typeOfInternship,
        timestamp: timestamp,
      };

      if (isRegister) {
        let data = await internshipRegistrationServices.AddIntern(
          files,
          jsonData
        );

        if (data === false) {
          console.log("Something went wrong.");
        } else {
          if (data.code === 200) {
            console.log("Intern Added Successfully. ", data.responseBody);
            window.location.replace("/success");
          } else {
            console.log(data);
            console.log("Invalid intern details.");
          }
        }
        // window.location.replace("/success");
      } else {
        let data = await internshipCertificateServices.AddCertificateDetails(
          jsonData
        );

        if (data.code === 200) {
          console.log(
            "Certificate Details Added Successfully. ",
            data.responseBody
          );
          // resetValues();
          window.location.replace("/success");
        } else if (data.code === 209) {
          console.log("209: " + data.responseBody);
          if (data.responseBody.includes("Email")) {
            setEmailError("This Email already exists");
          }
          if (data.responseBody.includes("Phone number")) {
            setPhoneNoError("This Phone Number already exists");
          }
        } else {
          console.log("Invalid intern details.");
        }
      }
    }
  };

  return (
    <div
      onContextMenu={handleContextMenu}
      style={{ display: "flex", justifyContent: "center", userSelect: "none" }}
    >
      <Grid
        container
        direction={"column"}
        sx={{
          width: "40%",
          justifyContent: "center",
          boxShadow: 5,
          gap: 2,
          m: 2,
          p: 5,
          border: 1,
          backgroundColor: "#F5FEFD",
          "@media screen and (max-width: 1250px)": {
            width: "60%",
          },
          "@media screen and (max-width: 750px)": {
            width: "80%",
          },
          "@media screen and (max-width: 500px)": {
            width: "100%",
            margin: "1px",
            padding: "10px",
          },
        }}
      >
        {/* <Grid item width="90%" textAlign="justify">
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              justifyContent: "center",
              fontWeight: "bold",
              padding: "2% 0 2% 0",
            }}
          >
            Read the Instructions carefully before filling the form.
          </Typography>
          <Typography sx={{ paddingBottom: "2%" }}>
            <li>
              Ensure you have a stable internet connection before filling the
              form.
            </li>

            <li>
              Use proper capitalization, spelling, and grammar while typing your
              responses.
            </li>

            <li>
              All mandatory fields must be filled before submitting the form.
            </li>

            <li>
              Upload the required documents attachments, look for the "Upload"
              or "Attach" button.
            </li>

            <li>
              Provide accurate contact details, including email and phone
              number. This ensures that you can be reached if there are any
              questions.
            </li>

            <li>
              Before finalizing your submission, carefully review all entries
              for accuracy and completeness.
            </li>

            <li>
              Once you are confident that all information is accurate, click the
              "Next" button.
            </li>
          </Typography>
        </Grid> */}
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontFamily: "inherit",
            fontWeight: "bold",
            textTransform: "uppercase",
            mb: -2,
          }}
        >
          Internship {isRegister ? "Registration" : "Details"} Form
        </Typography>

        <Grid sx={{ display: "flex", justifyContent: "right" }}>
          <Tooltip title="Read Instructions">
            <Button
              sx={{ fontFamily: "inherit", textTransform: "capitalize", py: 0 }}
              onClick={() => setOpen(true)}
            >
              Read Instructions
            </Button>
          </Tooltip>
        </Grid>

        <Grid item sx={sectionGridStyle} display={isRegister ? "" : "none"}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Upload Resume/CV
          </Typography>
          <Box sx={sectionContentStyle}>
            <Dropzone
              onDrop={handleResumeFileChange}
              accept={".pdf"}
              maxSize={2 * 1024 * 1024}
            >
              {({ getRootProps, getInputProps }) => (
                <Grid
                  item
                  {...getRootProps()}
                  sx={{
                    display: "block",
                    cursor: "pointer",
                    border: 1,
                    borderStyle: "dotted",
                    textAlign: "center",
                    alignSelf: "center",
                    p: 1,
                  }}
                >
                  <CloudUploadIcon fontSize="large" />
                  <Typography>
                    Drag and drop your resume here or click to select a file
                    locally
                  </Typography>
                  <Typography>Accepted file type: PDF</Typography>
                  <Typography>Max file size: 2MB</Typography>
                  <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                    {resumeFile ? "Selected File: " + resumeFile.name : ""}
                  </Typography>
                  <input {...getInputProps()} />
                </Grid>
              )}
            </Dropzone>
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Box sx={sectionContentStyle}>
            <Autocomplete
              id=""
              disableClearable
              readOnly={domainId || !isRegister}
              {...domainProps}
              value={selectedDomain}
              onChange={handleSelectedDomainChange}
              onBlur={() => {
                setSelectedDomainError(
                  selectedDomain ? "" : "This field is required"
                );
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label="Domain"
                  variant="standard"
                  error={Boolean(selectedDomainError)}
                  helperText={selectedDomainError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option.name}
                </Typography>
              )}
            />

            <Autocomplete
              sx={{ width: "100%" }}
              id=""
              readOnly={durationIndex || !isRegister}
              required
              disableClearable
              {...durationProps}
              value={duration}
              onChange={handleDurationChange}
              onBlur={() => {
                setDurationError(duration ? "" : "This field is required");
              }}
              renderInput={(params) => (
                <CustomTextField
                  required
                  {...params}
                  label="Select Duration"
                  variant="standard"
                  error={Boolean(durationError)}
                  helperText={durationError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Personal Information
          </Typography>
          <Box sx={sectionContentStyle}>
            <CustomTextField
              required
              id="name"
              label="Full-Name"
              variant="standard"
              value={name}
              onChange={handlenamechange}
              error={Boolean(nameError)}
              helperText={nameError}
              onBlur={() => {
                setNameError(name ? "" : "This field is required");
              }}
            />

            <CustomTextField
              required
              id="email"
              label="E-mail"
              type="email"
              variant="standard"
              value={email}
              onChange={handleEmailchange}
              error={Boolean(emailError)}
              helperText={emailError}
              onBlur={() => {
                setEmailError(email ? "" : "This field is required");
              }}
            />

            <CustomTextField
              required
              id="phoneNo"
              label="Phone No."
              variant="standard"
              value={phoneNo}
              onChange={handlePhoneNochange}
              error={Boolean(phoneNoError)}
              helperText={phoneNoError}
              onBlur={() => {
                setPhoneNoError(phoneNo ? "" : "This field is required");
              }}
            />

            <Autocomplete
              sx={{ width: "100%" }}
              id=""
              required
              disableClearable
              {...genderProps}
              value={gender}
              onChange={handleGenderChange}
              onBlur={() => {
                setGenderError(gender ? "" : "This field is required");
              }}
              renderInput={(params) => (
                <CustomTextField
                  required
                  {...params}
                  label="Gender"
                  variant="standard"
                  error={Boolean(genderError)}
                  helperText={genderError}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "inherit",
                  }}
                  {...props}
                >
                  {option}
                </Typography>
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                id=""
                format="DD-MM-YYYY"
                value={dob}
                label="Date of Birth"
                onChange={handleDOBChange}
                slotProps={{
                  textField: {
                    required: true,
                    variant: "standard",
                    helperText: dobError,
                    error: Boolean(dobError),
                    onBlur: () => {
                      setDOBError(dob != null ? "" : "This field is required");
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <CustomTextField
              required
              id="city"
              label="City"
              //helperText="Some important text"

              variant="standard"
              value={city}
              onChange={handleCitychange}
              error={Boolean(cityError)}
              helperText={cityError}
              onBlur={() => {
                setCityError(city ? "" : "This field is required");
              }}
            />
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Academic Information
          </Typography>
          <Box sx={sectionContentStyle}>
            <CustomTextField
              required
              id="degree"
              label="Degree"
              // helperText="Some important text"

              variant="standard"
              value={degree}
              onChange={handleDegreechange}
              error={Boolean(degreeError)}
              helperText={degreeError}
              onBlur={() => {
                setDegreeError(degree ? "" : "This field is required");
              }}
            />

            <CustomTextField
              required
              id="branch"
              label="Branch"
              // helperText="Some important text"

              variant="standard"
              value={branch}
              onChange={handleBranchchange}
              error={Boolean(branchError)}
              helperText={branchError}
              onBlur={() => {
                setBranchError(branch ? "" : "This field is required");
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                id="yop"
                views={["year"]}
                value={yop}
                label="Year of Completion"
                onChange={handleYOPchange}
                slotProps={{
                  textField: {
                    required: true,
                    variant: "standard",
                    helperText: yopError,
                    error: Boolean(yopError),
                    onBlur: () => {
                      setYOPError(yop != null ? "" : "This field is required");
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <CustomTextField
              required
              id="cgpa"
              label="cgpaPercentage/Percentage"
              //helperText="Some important text"

              variant="standard"
              value={cgpaPercentage}
              onChange={handlecgpaPercentagechange}
              error={Boolean(cgpaPercentageError)}
              helperText={cgpaPercentageError}
              onBlur={() => {
                setCgpaPercentageError(
                  cgpaPercentage ? "" : "This field is required"
                );
              }}
            />

            <CustomTextField
              required
              id="college_Name"
              label="College Name"
              //helperText="Some important text"

              variant="standard"
              value={collegeName}
              onChange={handleCollegeNamechange}
              error={Boolean(collegeNameError)}
              helperText={collegeNameError}
              onBlur={() => {
                setCollegeNameError(
                  collegeName ? "" : "This field is required"
                );
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                id="yop_12"
                views={["year"]}
                value={diploma12Yop}
                label="Diploma/12th Year of Completion"
                onChange={handleDiploma12Yopchange}
                slotProps={{
                  textField: {
                    required: true,
                    variant: "standard",
                    helperText: diploma12YopError,
                    error: Boolean(diploma12YopError),
                    onBlur: () => {
                      setDiploma12YopError(
                        diploma12Yop != null ? "" : "This field is required"
                      );
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <CustomTextField
              required
              id="percentage_12"
              label="12th/Diploma Percentage"
              //helperText="Some important text"

              variant="standard"
              value={diploma12Percentage}
              onChange={handleDiploma12Percentagechange}
              error={Boolean(diploma12PercentageError)}
              helperText={diploma12PercentageError}
              onBlur={() => {
                setDiploma12PercentageError(
                  diploma12Percentage ? "" : "This field is required"
                );
              }}
            />

            <CustomTextField
              required
              id="college_12"
              label="12th/Diploma College Name"
              //helperText="Some important text"

              variant="standard"
              value={diploma12College}
              onChange={handlediploma12Collegechange}
              error={Boolean(diploma12CollegeError)}
              helperText={diploma12CollegeError}
              onBlur={() => {
                setDiploma12CollegeError(
                  diploma12College ? "" : "This field is required"
                );
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                id="yop_10"
                views={["year"]}
                value={yop10}
                label="10th Year of Completion"
                onChange={handleYop10change}
                slotProps={{
                  textField: {
                    required: true,
                    variant: "standard",
                    helperText: yop10Error,
                    error: Boolean(yop10Error),
                    onBlur: () => {
                      setYop10Error(
                        yop10 != null ? "" : "This field is required"
                      );
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <CustomTextField
              required
              id="percentage_10"
              label="10th Percentage"
              //helperText="Some important text"

              variant="standard"
              value={percentage10}
              onChange={handlePercentage10change}
              error={Boolean(percentage10Error)}
              helperText={percentage10Error}
              onBlur={() => {
                setPercentage10Error(
                  percentage10 ? "" : "This field is required"
                );
              }}
            />

            <CustomTextField
              required
              id="school_10"
              label="10th School Name"
              //helperText="Some important text"

              variant="standard"
              value={schoolName10}
              onChange={handleSchoolName10change}
              error={Boolean(schoolName10Error)}
              helperText={schoolName10Error}
              onBlur={() => {
                setSchoolName10Error(
                  schoolName10 ? "" : "This field is required"
                );
              }}
            />

            <CustomTextField
              required
              id="active_Backlog"
              label="Active Backlogs"
              // helperText="Some important text"

              variant="standard"
              value={activeBacklog}
              onChange={handleactiveBacklogchange}
              error={Boolean(activeBacklogError)}
              helperText={activeBacklogError}
              onBlur={() => {
                setActiveBacklogError(
                  activeBacklog ? "" : "This field is required"
                );
              }}
            />
          </Box>
        </Grid>

        <Grid item sx={sectionGridStyle} display={isRegister ? "" : "none"}>
          <Typography variant="body1" sx={sectionHeadingStyle}>
            Required Documents
          </Typography>
          <Box sx={sectionContentStyle}>
            <CustomFileInput
              variant="standard"
              required
              id="photo"
              value={photoFile}
              onChange={handleChangePhoto}
              label="Upload you photo"
              error={Boolean(photoFileError)}
              helperText={photoFileError}
              onBlur={() => {
                setPhotoFileError(photoFile ? "" : "This field is required");
              }}
            />

            <CustomFileInput
              variant="standard"
              required
              id="id_Proof"
              value={idFile}
              onChange={handleChangeID}
              label="Upload you ID Proof"
              error={Boolean(idFileError)}
              helperText={idFileError}
              onBlur={() => {
                setIdFileError(idFile ? "" : "This field is required");
              }}
            />

            <CustomFileInput
              variant="standard"
              required
              id="payment_receipt"
              value={paymentReceiptFile}
              onChange={handleChangePaymentReceipt}
              label="Upload you Payment Receipt"
              error={Boolean(paymentReceiptFileError)}
              helperText={paymentReceiptFileError}
              onBlur={() => {
                setPaymentReceiptFileError(
                  paymentReceiptFile ? "" : "This field is required"
                );
              }}
            />
          </Box>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "teal" }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Box>
      </Grid>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Form_Instructions onClick={() => setOpen(false)} />
      </Modal>
    </div>
  );
}

export default Intern;
