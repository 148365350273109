import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class TraineeCertificateServices {
  async AddCertificateDetails(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/form/TraineeCertificate/add`,
        data,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }
}
const traineeCertificateServices = new TraineeCertificateServices();
export default traineeCertificateServices;
