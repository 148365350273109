import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";

function Form_Instructions({ onClick }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "grid",
          justifyContent: "center",
          gap: 1,
          width: "40%",
          bgcolor: "white",
          "@media screen and (max-width: 1250px)": {
            width: "40%",
          },
          "@media screen and (max-width: 750px)": {
            width: "60%",
          },
          "@media screen and (max-width: 500px)": {
            width: "80%",
          },
          p: 5,
          py: 3,
          boxShadow: 5,
          border: 1,
          height: "90vh",
          overflowY: "auto",
          border: 1,
        }}
      >
        <Divider />
        <Typography variant="body1" fontFamily="inherit" sx={{ ml: 0 }}>
          <strong>1. Carefully Review Pre-filled Information:</strong>
          <ul>
            <li>
              <strong>Verify Personal Details:</strong> Check that your full
              name, email ID, phone number, and any other pre-filled personal
              information are correct. Any errors here could lead to issues with
              processing your form.
            </li>
            <li>
              <strong>Review Address Information:</strong> Ensure that your
              current address and any other address information provided are
              accurate.
            </li>
            <li>
              <strong>Check Identification Numbers:</strong> If the form
              includes fields like Social Security Number, passport number, or
              any other ID numbers, verify these carefully.
            </li>
          </ul>
        </Typography>

        <Typography variant="body1" fontFamily="inherit">
          <strong>2. Fill in the Required Fields:</strong>
          <ul>
            <li>
              <strong>Mandatory Fields:</strong> Complete all fields marked with
              an asterisk (*) as they are mandatory. Missing these fields may
              result in your form being rejected.
            </li>
            <li>
              <strong>Accurate and Up-to-Date Information:</strong> Provide the
              most current and truthful information in each field. Double-check
              dates, spellings, and numerical entries.
            </li>
            <li>
              <strong>Supporting Documents:</strong> If any fields require
              uploading documents (such as a photo ID or proof of address),
              ensure these documents are scanned clearly and uploaded correctly.
            </li>
          </ul>
        </Typography>

        <Typography variant="body1" fontFamily="inherit">
          <strong>3. Check for Errors:</strong>
          <ul>
            <li>
              <strong>Spelling and Grammar:</strong> Review all text for
              spelling and grammar errors. Mistakes can lead to
              misunderstandings or delays.
            </li>
            <li>
              <strong>Numeric Entries:</strong> Verify numbers, such as dates,
              phone numbers, or financial figures, for accuracy.
            </li>
            <li>
              <strong>Consistency:</strong> Ensure that information is
              consistent across all sections of the form. For example, your name
              should be spelled the same way in every instance.
            </li>
          </ul>
        </Typography>

        <Typography variant="body1" fontFamily="inherit">
          <strong>4. No Corrections Allowed Post-Submission:</strong>
          <ul>
            <li>
              <strong>Final Review:</strong> Take the time to go over the entire
              form thoroughly before submitting. Check each section for
              completeness and accuracy.
            </li>
            <li>
              <strong>Seek Assistance if Needed:</strong> If you are unsure
              about any section of the form, seek assistance from a trusted
              individual or contact the support team for guidance.
            </li>
            <li>
              <strong>Legal Implications:</strong> Understand that submitting
              incorrect information can have legal consequences. Make sure all
              details are correct.
            </li>
          </ul>
        </Typography>

        <Typography variant="body1" fontFamily="inherit">
          <strong>5. Submission:</strong>
          <ul>
            <li>
              <strong>Certainty Before Submission</strong>: Only submit the form
              when you are absolutely sure that all the information is accurate
              and complete.
            </li>
            <li>
              <strong>Confirmation:</strong> After submission, you may receive a
              confirmation email or a submission receipt. Keep this for your
              records.
            </li>
            <li>
              <strong>Follow-Up:</strong> Be aware of any follow-up steps
              required after submission, such as confirming receipt or waiting
              for further instructions.
            </li>
          </ul>
        </Typography>
        <Divider />
        <Box>
          <Button sx={{ float: "right" }} variant="contained" onClick={onClick}>
            Close
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default Form_Instructions;
