const ThankYouMessage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.centered}>
        <h2>Thanks for submitting the form!</h2>
        <p>We will get back to you soon.</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  centered: {
    textAlign: "center",
  },
};

export default ThankYouMessage;
