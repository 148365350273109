const { styled } = require("@mui/material");
const { DatePicker } = require("@mui/x-date-pickers");

const CustomDatePicker = styled(DatePicker)({
  width: "100%",

  "& .MuiInput-root , & .MuiFormHelperText-root , & .MuiInputLabel-root, & .MuiFormHelperText-root":
    { fontSize: 14, fontFamily: "inherit", color: "black" },

  "& .MuiInput-root": { marginTop: 12 },

  "& .MuiFormHelperText-root": {},

  // On Focus
  "&:focus-within ": {
    "& .MuiInputLabel-root": {
      color: "black",
    },

    "& .MuiInput-root": {
      marginTop: 12,
    },
  },

  // On Hover
  "&:hover": {
    "& .MuiInput-root": {
      borderBottom: "0px solid black",
    },
  },
});

export default CustomDatePicker;
