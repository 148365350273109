import "./App.css";
import Intern from "./Forms/Intern/Intern";
import ThankYouMessage from "./Forms/ThankYouMessage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Trainer from "./Forms/Trainer/Trainer";
import Trainee from "./Forms/Trainee/Trainee";
import Home from "./Home";
import InternEmailConfirmation from "./Forms/Intern/InternEmailConfirmation";
import TraineeEmailConfirmation from "./Forms/Trainee/TraineeEmailConfirmation";
import Form_Instructions from "./Content/Instructions/Form_Instructions";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tpp" element={<Form_Instructions />} />
          {/* INTERN */}
          <Route
            path="/intern/register/:internType?/:domainId?/:durationIndex?"
            element={<Intern />}
          />
          <Route path="/intern/details" element={<Intern />} />
          <Route
            path="/intern/email-verify"
            element={<InternEmailConfirmation />}
          />

          {/* TRAINER */}
          <Route path="/careers/trainer/:jobId?" element={<Trainer />} />

          {/* TRAINEE */}
          <Route path="/trainee/register/:courseId?" element={<Trainee />} />
          <Route path="/trainee/details" element={<Trainee />} />
          <Route
            path="/trainee/email-verify"
            element={<TraineeEmailConfirmation />}
          />

          <Route path="/success" element={<ThankYouMessage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
