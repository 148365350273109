import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class DomainServices {
  async getDomains() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/domain/list`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }
}

const domainServices = new DomainServices();
export default domainServices;
