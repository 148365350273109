import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../GenericComponents/CustomTextField";
import internshipRegistrationServices from "../../Services/InternshipRegistrationServices";
import { useNavigate } from "react-router-dom";
import traineeServices from "../../Services/TraineeServices";

function TraineeEmailConfirmation() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const handleEmailChange = (event) => {
    const { value } = event.target; // Only allow alphanumeric and Special  characters
    const newValue = value.replace(/[^A-Za-z0-9@._]/g, "");
    setEmail(newValue);
  };

  const handleButtonClick = async () => {
    let data = await traineeServices.GetTraineeByEmail(email);

    if (data === false) {
      console.log("Something went wrong.");
    } else {
      if (data.code === 200) {
        console.log("Intern Data Fetched Successfully. ", data.responseBody);
        const body = data.responseBody;
        navigate("/trainee/details", { state: body });
      } else {
        console.log(data);
        console.log("Invalid email");
      }
    }
  };

  useEffect(() => {
    setIsDisabled(() => (email ? false : true));
  }, [email]);

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 3,
            width: "20%",
            "@media screen and (max-width: 1250px)": {
              width: "40%",
            },
            "@media screen and (max-width: 750px)": {
              width: "60%",
            },
            "@media screen and (max-width: 500px)": {
              width: "80%",
            },
            p: 5,
            boxShadow: 5,
            borderRadius: 5,
            border: 1,
          }}
        >
          <CustomTextField
            sx={{ width: "100%" }}
            required
            id="email"
            label="Enter your registered email"
            value={email}
            onChange={handleEmailChange}
            // error={Boolean(nameError)}
            // helperText={nameError}
            // onBlur={() => {
            //   setNameError(name ? "" : "This field is required");
            // }}
          />

          <Button
            disabled={isDisabled}
            variant="contained"
            onClick={handleButtonClick}
          >
            Verify Email
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default TraineeEmailConfirmation;
