import React, { useEffect, useState } from "react";
import jobRoleServices from "./Services/JobRoleServices";
import trainingCourseServices from "./Services/TrainingCourseServices";
import domainServices from "./Services/DomainServices";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDatePicker from "./GenericComponents/CustomDatePicker";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CustomTextField from "./GenericComponents/CustomTextField";
import dayjs from "dayjs";
import { isIS } from "@mui/x-date-pickers/locales";
import { validateDate } from "@mui/x-date-pickers/internals";

const sectionGridStyle = {
  borderRadius: 2,
  p: 2,
  boxShadow: 1,
  backgroundColor: "#F5F5F5",
  m: 1,
  width: "500px",
};

const buttonStyle = {
  textTransform: "capitalize",
  fontFamily: "inherit",
  my: 1,
  p: 1,
  width: "100%",
};

function Home() {
  const navigate = useNavigate();
  const host = window.location.host;

  const [isIntern, setIsIntern] = useState(false);

  const [jobRoles, setJobRoles] = useState([]);
  const [courses, setCourses] = useState([]);
  const [domains, setDomains] = useState([]);
  const [duration, setDuration] = useState("");

  useEffect(() => {
    const fetchJobRoles = async () => {
      let data = await jobRoleServices.getJobRoles();

      if (data === false) {
        console.log("Something wnt wrong.");
      } else {
        if (data.code === 200) {
          console.log(data.responseBody);
          setJobRoles(() => data.responseBody);
        }
      }
    };

    fetchJobRoles();

    const fetchCourses = async () => {
      let data = await trainingCourseServices.getTrainingCourses();

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log(data.responseBody);
          setCourses(() => data.responseBody);
        }
      }
    };

    fetchCourses();

    const fetchDomains = async () => {
      let data = await domainServices.getDomains();

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log(data.responseBody);
          setDomains(() => data.responseBody);
        }
      }
    };

    fetchDomains();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  // const [startDate, setStartDate] = useState(null);
  const [internType, setInternType] = useState({ id: "", value: "" });
  const [currentPath, setCurrentPath] = useState("");
  const [currentSubPath, setCurrentSubPath] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState(" ");
  const [isDisableGenerateUrl, setIsDisabledGenerateUrl] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    // setStartDate(null);
    setInternType({ id: "", value: "" });
    setDuration("");
    setGeneratedUrl(" ");
    setIsDisabledGenerateUrl(false);
  };

  // const handleStartDateChange = (value) => {
  //   setStartDate(value);
  //   setIsDisabledGenerateUrl(internType != null ? false : true);
  // };

  const handleInternTypeChange = (event, value) => {
    setInternType(value);
    setGeneratedUrl(" ");
    setIsDisabledGenerateUrl(false);
    // setIsDisabledGenerateUrl(startDate != null ? false : true);
  };

  const handleDurationChange = (event, value) => {
    setDuration(value);
    setIsDisabledGenerateUrl(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedUrl);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Autocomplete Props
  const internTypes = [
    { id: "u", value: "Unpaid" },
    { id: "p", value: "Paid" },
    { id: "s", value: "Stipend Based" },
  ];
  const internTypeProps = {
    options: internTypes,
    getOptionLabel: (option) => option.value,
  };

  const durationOptions = ["1 month", "2 months", "3 months", "6 months"];
  const durationProps = {
    options: durationOptions,
  };

  const handleGenerateUrlClick = () => {
    // const dateString = startDate.format("DD-MM-YYYY").replaceAll("-", "");

    // const day = parseInt(dateString.substring(0, 2), 10);
    // const month = parseInt(dateString.substring(2, 4), 10) - 1; // Months are 0-indexed in JavaScript Date objects
    // const year = parseInt(dateString.substring(4, 8), 10);

    // const dateObject = new Date(year, month, day);
    const additionalSubPath =
      internType.id === "s"
        ? "/" + durationOptions.findIndex((item) => item === duration)
        : "";
    setGeneratedUrl(
      host +
        currentPath +
        "/" +
        internType.id +
        "/" +
        currentSubPath +
        additionalSubPath
    );
  };

  const handleButtonClick = (path, isInternStatus, subPath = "") => {
    setIsIntern(isInternStatus);
    if (!isInternStatus) {
      setGeneratedUrl(host + path);
    }
    setCurrentPath(path);
    setCurrentSubPath(subPath);
    setOpenModal(true);
    setIsDisabledGenerateUrl(true);
  };

  return (
    <div
      style={{
        textAlign: "center",
        padding: "1% 5% 0 5%",
        overflowX: "auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{ m: 2, fontFamily: "inherit", fontWeight: "500" }}
      >
        FORM MODULE
      </Typography>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", overflowX: "auto" }}
      >
        {/* TRAINER FORMS */}
        <Grid item sx={sectionGridStyle}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontFamily: "inherit",
              mb: 2,
            }}
          >
            Trainer Application Forms
          </Typography>
          <Divider />
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => handleButtonClick("/careers/trainer", false)}
          >
            Blank Form
          </Button>
          <Divider />
          <Typography
            sx={{
              fontFamily: "inherit",
              p: 1,
            }}
          >
            Forms for specific Job Roles
          </Typography>
          {jobRoles.map((item) => (
            <Button
              variant="outlined"
              sx={buttonStyle}
              onClick={() =>
                handleButtonClick("/careers/trainer/" + item.id, false)
              }
            >
              {item.role}
            </Button>
          ))}
        </Grid>

        {/* TRAINEE FORMS */}
        <Grid item sx={sectionGridStyle}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontFamily: "inherit",
              mb: 2,
            }}
          >
            Trainee Registration Forms
          </Typography>
          <Divider />
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => handleButtonClick("/trainee/register", false)}
          >
            Blank Form
          </Button>
          <Divider />
          <Typography
            sx={{
              fontFamily: "inherit",
              p: 1,
            }}
          >
            Forms for specific Courses
          </Typography>
          {courses.map((item) => (
            <Button
              variant="outlined"
              sx={buttonStyle}
              onClick={() =>
                handleButtonClick("/trainee/register/" + item.id, false)
              }
            >
              {item.name +
                " (" +
                item.duration +
                (item.duration === "1" ? " month)" : " months)")}
            </Button>
          ))}
        </Grid>

        {/* INTERN FORMS */}
        <Grid item sx={sectionGridStyle}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontFamily: "inherit",
              mb: 2,
            }}
          >
            Intern Registration Forms
          </Typography>
          <Divider />
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => handleButtonClick("/intern/register", true)}
          >
            Blank Form
          </Button>
          <Divider />
          <Typography
            sx={{
              fontFamily: "inherit",
              p: 1,
            }}
          >
            Forms for specific Domains
          </Typography>
          {domains.map((item) => (
            <Button
              variant="outlined"
              sx={buttonStyle}
              onClick={() =>
                handleButtonClick("/intern/register", true, item.id)
              }
            >
              {item.name}
            </Button>
          ))}
        </Grid>

        {/* CERTIFICATE FORMS */}
        <Grid item sx={sectionGridStyle}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontFamily: "inherit",
              mb: 2,
            }}
          >
            Certificate Information Forms
          </Typography>
          <Divider />
          <Typography
            sx={{
              fontFamily: "inherit",
              p: 1,
            }}
          >
            For Trainee
          </Typography>
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => handleButtonClick("/trainee/email-verify", false)}
          >
            Click here
          </Button>
          <Divider />
          <Typography
            sx={{
              fontFamily: "inherit",
              p: 1,
            }}
          >
            For Intern
          </Typography>

          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => handleButtonClick("/intern/email-verify", false)}
          >
            Click here
          </Button>
        </Grid>
      </Grid>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            display: "grid",
            gap: 5,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 5,
            borderRadius: 5,
            p: 5,
            width: "20%",
            "@media screen and (max-width: 1250px)": {
              width: "40%",
            },
            "@media screen and (max-width: 750px)": {
              width: "60%",
            },
            "@media screen and (max-width: 500px)": {
              width: "80%",
            },
          }}
        >
          {isIntern && (
            <>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  id=""
                  format="DD-MM-YYYY"
                  value={startDate}
                  label="Select Start date for the Internship"
                  onChange={handleStartDateChange}
                  slotProps={{
                    textField: {
                      required: true,
                      variant: "standard",
                      // helperText: dobError,
                      // error: Boolean(dobError),
                      // onBlur: validateDob,
                    },
                  }}
                />
              </LocalizationProvider> */}
              <Autocomplete
                sx={{ width: "100%" }}
                id=""
                required
                disableClearable
                {...internTypeProps}
                value={internType}
                onChange={handleInternTypeChange}
                // onBlur={validateGender}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Select the type of internship"
                    variant="standard"
                    // error={Boolean(genderError)}
                    // helperText={genderError}
                  />
                )}
                renderOption={(props, option) => (
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: "inherit",
                    }}
                    {...props}
                  >
                    {option.value}
                  </Typography>
                )}
              />

              <>
                <Autocomplete
                  sx={{
                    width: "100%",
                    display: internType.id === "s" ? "" : "none",
                  }}
                  id=""
                  required
                  disableClearable
                  {...durationProps}
                  value={duration}
                  onChange={handleDurationChange}
                  onBlur={() => {
                    // setDurationError(
                    //   duration ? "" : "This field is required"
                    // );
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      required
                      {...params}
                      label="Select Duration"
                      variant="standard"
                      // error={Boolean(durationError)}
                      // helperText={durationError}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "inherit",
                      }}
                      {...props}
                    >
                      {option}
                    </Typography>
                  )}
                />
              </>
            </>
          )}

          <CustomTextField
            value={generatedUrl}
            label="Generated URL"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleCopy}>
                    <ContentCopyRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isIntern && (
            <>
              <Button
                disabled={isDisableGenerateUrl}
                variant="contained"
                onClick={handleGenerateUrlClick}
              >
                Generate URL
              </Button>{" "}
            </>
          )}
        </Box>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="Text copied to clipboard"
      />
    </div>
  );
}

export default Home;
